"use client";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SearchPageLayout from "@/components/Layouts/SearchPageLayout/SearchPageLayout";
import HibtService from "@/services/hibtService";
import { useSearchParams } from "next/navigation";
import { useGoogleReCaptcha } from "react-google-recaptcha-hook";
import { ItemDataType, ListTypeId, MaterializeSearchResultItem } from "@/types";
import Table from "@/components/Table/Table";
import LoadingState from "@/components/states/LoadingState";
import {
  inDatasetFilterOptions,
  itemsPerPageOptions,
  mediaTypeFilterOptions,
} from "@/utils/consts";
import PaginationWrapper from "@/components/PaginationWrapper/PaginationWrapper";
import TableControls from "@/components/Table/TableControls/TableControls";
import InfoSection from "@/components/InfoSection/InfoSection";
import { useAppSelector } from "../../../app/GlobalRedux/store";
import { setSnackBar } from "../../../app/GlobalRedux/Features/snackBarSlice";
import EmptyState from "@/components/states/EmptyState/EmptyState";
import ErrorState from "@/components/states/ErrorState/ErrorState";
import Logging from "@/utils/Logging";
import mixpanel from "mixpanel-browser";
import NavBar from "@/components/NavBar/NavBar";
import Footer from "@/components/Footer/Footer";

const ExtensionSearchResults = () => {
  const searchParams = useSearchParams();
  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    process.env.NEXT_PUBLIC_REACT_GACAPTV3_SITE_KEY || "",
    { hide: true }
  );

  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const lists = useAppSelector((state) => state.auth.lists);

  const materializeId = searchParams.get("materialize");
  const salt = searchParams.get("salt");

  // Pagination items
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    itemsPerPageOptions[1].value
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: itemsPerPage,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [dataTotal, setDataTotal] = useState(0);
  const [data, setData] = useState<MaterializeSearchResultItem[]>([]);
  const [dataToDisplay, setDataToDisplay] = useState<
    MaterializeSearchResultItem[]
  >([]);

  const [imagesFoundInDataSet, setImagesFoundInDataSet] = useState<
    Record<string, { url: string; caption: string }>
  >({});
  const [numberOfImagesFoundInDataSet, setNumberOfImagesFoundInDataSet] =
    useState(0);
  const [shouldCheckUrls, setShouldCheckUrls] = useState(false);

  // Filtering options
  const [mediaTypeValueToFilterBy, setMediaTypeValueToFilterBy] = useState<
    "all" | ItemDataType
  >(mediaTypeFilterOptions[0].value);
  const [inDatasetValueToFilterBy, setInDatasetValueToFilterBy] = useState<
    "all" | "inDataset"
  >(inDatasetFilterOptions[0].value);

  const checkForFoundUrls = async (urls: string[]) => {
    try {

      // break urls into chunks of 20 urls
      const urlBatches = [];
      for (let i = 0; i < urls.length; i += 20) {
        urlBatches.push(urls.slice(i, i + 20));
      }

      for (const batch of urlBatches) {
        const recaptchaResponse = await executeGoogleReCaptcha("submit");

        const { data } = await HibtService.checkForUrlsFoundInTheDataset(
          recaptchaResponse,
          batch
        );

        const urlsOfFoundImages = data.urls.reduce(
          (
            acc: Record<string, { url: string; caption: string }>,
            cur: MaterializeSearchResultItem
          ) => ({
            ...acc,
            [cur.url]: { url: cur.url, caption: cur.caption },
          }),
          {}
        );
        setImagesFoundInDataSet((prev) => ({
          ...prev,
          ...urlsOfFoundImages,
        }));
        setNumberOfImagesFoundInDataSet((prev) => prev + urlsOfFoundImages.length);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - checkForUrlsFoundInTheDataset", {
        pageName: "ExtensionSearchResults",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  const loadExtensionData = async () => {
    if (!materializeId || !salt) return;
    mixpanel.track("Extension Search Results Page");

    setIsLoading(true);

    const captchaKey = await executeGoogleReCaptcha("submit");
    try {
      const { records, total } = await HibtService.getMaterializeSearchResults(
        materializeId,
        captchaKey,
        salt
      );
      const transformedRecords = records.map((record) => ({
        ...record,
        type: record.data_type,
      }));

      setData(transformedRecords);
      setDataTotal(total);
      setPagination({ ...pagination, count: total });
      setShouldCheckUrls(true);
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsLoading(false);
      setIsError(true);

      Logging.networkError("API Error - getMaterializeSearchResults", {
        pageName: "ExtensionSearchResults",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });

      dispatch(
        setSnackBar({
          open: true,
          message: `An error occurred: ${error.message}`,
          variant: "error",
        })
      );
    }
  };

  useEffect(() => {
    loadExtensionData();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!shouldCheckUrls || !data) return;

    const handleImagesFoundInDataSet = async () => {
      await checkForFoundUrls(data.map(({ url }) => url));
      setShouldCheckUrls(false);
    };

    handleImagesFoundInDataSet();
  }, [shouldCheckUrls, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data) return;
    const transformDataToIncludeIsInDataSetProperty = () => {
      const transformedData = data.map((item) => ({
        ...item,
        is_in_dataset: !!imagesFoundInDataSet[item.url],
        caption: imagesFoundInDataSet[item.url]?.caption,
      }));
      setData(transformedData);
    };
    transformDataToIncludeIsInDataSetProperty();
  }, [imagesFoundInDataSet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const paginatedData = data.slice(pagination.from, pagination.to);
    if (inDatasetValueToFilterBy === "inDataset") {
      const filteredData = data.filter(({ is_in_dataset }) => is_in_dataset);
      setPagination((pag) => ({ ...pag, count: filteredData.length }));
      setDataToDisplay(filteredData.slice(pagination.from, pagination.to));
    } else if (mediaTypeValueToFilterBy !== "all") {
      const filteredData = data.filter(
        ({ type }) => mediaTypeValueToFilterBy === type
      );
      setPagination((pag) => ({ ...pag, count: filteredData.length }));
      setDataToDisplay(filteredData.slice(pagination.from, pagination.to));
    } else {
      setDataToDisplay(paginatedData);
    }
  }, [
    data,
    mediaTypeValueToFilterBy,
    inDatasetValueToFilterBy,
    currentPage,
    pagination.from,
    pagination.to,
  ]);

  useEffect(() => {
    // calculate the new page number based on the new items per page
    let newPageNumber = Math.ceil((pagination.from + 1) / itemsPerPage);
    if (newPageNumber <= 0) newPageNumber = 1;
    setCurrentPage(newPageNumber);

    // calculate new from and to
    const from = (newPageNumber - 1) * itemsPerPage;
    const to = itemsPerPage * newPageNumber;

    setPagination({ ...pagination, from, to });
  }, [itemsPerPage, mediaTypeValueToFilterBy, inDatasetValueToFilterBy]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOptingOutItems = async () => {
    if (!user || !lists) {
      return;
    }

    const selectedItems = data.filter(({ isChecked }) => isChecked);
    const doNotTrainList = lists.find(
      ({ type_id }) => type_id === ListTypeId.DO_NOT_TRAIN
    );

    const dataToSend = selectedItems.map(({ id, url, caption, type }) => ({
      id,
      url,
      caption,
      type_id: ListTypeId.DO_NOT_TRAIN,
      list_id: doNotTrainList?.id,
      type,
    }));
    try {
      const optOutResponse =
        await HibtService.insertMultipleObjectsToSelections(
          user.id_token,
          dataToSend
        );

      if (optOutResponse.status === 200) {
        dispatch(
          setSnackBar({
            open: true,
            message: `${dataToSend.length} ${
              dataToSend.length === 1 ? "item" : "items"
            } added to your`,
            variant: "doNotTrain",
          })
        );

        setData((prev) =>
          prev.map((item) => ({ ...item, isChecked: false }))
        );
      } else {
        dispatch(
          setSnackBar({
            open: true,
            message: `Failed to add ${dataToSend.length} ${
              dataToSend.length === 1 ? "item" : "items"
            } to your "Do Not Train" list`,
            variant: "doNotTrainError",
          })
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - handle Opting out items", {
        pageName: "ExtensionSearchResults",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });

      dispatch(
        setSnackBar({
          open: true,
          message: `An error occurred: ${error.message}`,
          variant: "doNotTrainError",
        })
      );
    }
  };

  const handleCheckBoxClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    url: string
  ) => {
    const { checked } = e.target;
    const newSelectionArray = data.map((item, index) =>
      item.url === url ? { ...item, isChecked: checked } : item
    );
    setData(newSelectionArray);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    url: string
  ) => {
    if (e.key === "Enter") {
      e.currentTarget.checked = true;
      const newSelectionArray = data.map((item) =>
        item.url === url
          ? { ...item, isChecked: e.currentTarget.checked }
          : item
      );
      setData(newSelectionArray);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      e.currentTarget.checked = false;
      const newSelectionArray = data.map((item) =>
        item.url === url
          ? { ...item, isChecked: e.currentTarget.checked }
          : item
      );
      setData(newSelectionArray);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault();
    const from = (page - 1) * itemsPerPage;
    const to = itemsPerPage * page;
    setCurrentPage(page);
    setPagination({ ...pagination, from, to });
  };

  const handleOnSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const selectAllItems = data.map((item, index) => ({
      ...item,
      isChecked: (index >= pagination.from && index < pagination.to) ? checked : item.isChecked,
    }));
    setData(selectAllItems);
  };

  const handleKeyDownSelectAll = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const { checked } = e.currentTarget;
      const selectAllItems = data.map((item, index) => ({
        ...item,
        isChecked: (index >= pagination.from && index < pagination.to) ? checked : item.isChecked,
      }));
      setData(selectAllItems);
    }
  };

  if (!materializeId || !salt) return null;

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <SearchPageLayout>
        {isError && <ErrorState />}
        <InfoSection
          valueSearched={{
            value: "Extension results",
            type: "EXTENSION_RESULTS",
          }}
          label="Extension results"
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          paginationCount={pagination.count}
          handlePageChange={handlePageChange}
        />

        <TableControls
          totalItems={dataTotal}
          pageNumber={currentPage}
          displayedItems={dataToDisplay.length}
          checkedItems={
            dataToDisplay.filter(({ isChecked }) => isChecked).length
          }
          itemsPerPage={itemsPerPage}
          itemsPerPageOptions={itemsPerPageOptions}
          setItemsPerPage={setItemsPerPage}
          handleOnSelectAllClick={handleOnSelectAllClick}
          handleKeyDownSelectAll={handleKeyDownSelectAll}
          mediaTypeValueToFilterBy={mediaTypeValueToFilterBy}
          setMediaTypeValueToFilterBy={setMediaTypeValueToFilterBy}
          numberOfItemsInDataset={numberOfImagesFoundInDataSet}
          inDatasetValueToFilterBy={inDatasetValueToFilterBy}
          setInDatasetValueToFilterBy={setInDatasetValueToFilterBy}
          handleOptingOutItems={handleOptingOutItems}
        />

        {dataToDisplay.length > 0 && !isLoading && !isError ? (
          <Table
            dataToDisplay={dataToDisplay}
            handleUrlCheckBoxClick={handleCheckBoxClick}
            handleUrlKeyDown={handleKeyDown}
          />
        ) : (
          <EmptyState>
            We were unable to find any results for your search. Please try
            again.
          </EmptyState>
        )}

        <PaginationWrapper
          currentPage={currentPage}
          paginationCount={pagination.count}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
        />
      </SearchPageLayout>
      <Footer />
    </>
  );
};

export default ExtensionSearchResults;
