import { Interweave } from "interweave";
import React, { Suspense } from "react";
import RichTextResolver, { ISbRichtext } from "storyblok-js-client";

interface RichTextProps {
  children: ISbRichtext;
  className?: string;
}

const RichText: React.FC<RichTextProps> = ({ children, className }) => {
  const resolveRichText = (text: ISbRichtext): string => {
    const { richTextResolver } = new RichTextResolver({});

    return richTextResolver.render(text);
  };

  return (
    <Suspense>
      <Interweave className={className} content={resolveRichText(children)} />
    </Suspense>
  );
};

export default RichText;
