"use client";
import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import SearchBar from "@/components/SearchBar/SearchBar";

import styles from "./HomePageContent.module.scss";
import Logging from "@/utils/Logging";
import { ISbStoryData } from "storyblok-js-client";
import mixpanel from "mixpanel-browser";
import RichText from "@/components/Richtext";
import { useSearchParams } from "next/navigation";

const HomePageContent = () => {
  const [storyBlokData, setStoryBlokData] = useState<ISbStoryData>();
  const searchParams = useSearchParams();

  useEffect(() => {
    const loadStoryBlokData = async () => {
      const { story } = await fetchData();

      setStoryBlokData(story);
    };

    loadStoryBlokData();
    mixpanel.track("Home Page Loaded");
  }, []);

  const content = storyBlokData?.content;
  const { underSearchBarText, bannerContent } = content || {};

  if (searchParams.get("materialize")) {
    return null;
  }

  return (
    <div className={styles.main}>
      <NavBar />

      <div className={styles.mainContentWrapper}>
        <h1 className={styles.header}>Have I Been Trained?</h1>
        <div className={styles.homePageSearchBarWrapper}>
          <SearchBar />
        </div>
        <p>{underSearchBarText}</p>
        {bannerContent && (
          <div className={styles.banner}>
            <RichText>{bannerContent}</RichText>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default HomePageContent;

async function fetchData() {
  const version =
    process.env.NODE_ENV === "development" ? "draft" : "published";

  try {
    const response = await fetch(
      `https://api-us.storyblok.com/v2/cdn/stories/hibt-v1-1/hibt-home-page?version=${version}&token=${process.env.NEXT_PUBLIC_STORYBLOK_API_KEY}`
    );
    const data = await response.json();

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    Logging.networkError("Storyblok Error - home page stroyblok", {
      pageName: "Home Page",
      errorMessage: error.message,
      errorCode: error.code,
      statusCode: error.response?.status,
    });
  }
}
